<template>
<div>
    <CCol sm="12">
        <CCardBody class="d-flex flex-column">
                <div role="group" class="row form-group">
                    <!-- Title -->
                    <div class="col-12 col-lg-8 mt-5 mb-2">
                        <label class="label-alert">Título</label>
                        <input type="text" v-model="alert.title" class="form-control" :class="{ 'is-invalid': submitted && hasError('Title') }"/>
                        <div v-if="submitted && hasError('Title')" class="invalid-feedback">
                            <span v-if="!$v.alert.title.required">É necessário o Título</span>
                            <span v-if="errors.Title">{{errors.Title}}</span>
                        </div>
                    </div>
                    <!-- Date & Time-->
                    <div class="col-12 col-lg-4 mt-5 mb-2">
                        <label class="label-alert">Data e hora</label>
                        <input type="datetime-local" v-model="alert.moment" class="form-control" :min="minDate" :class="{ 'is-invalid': submitted && hasError('Moment') }"/>
                        <div v-if="submitted && hasError('Moment')" class="invalid-feedback">
                            <span v-if="!$v.alert.moment.required">É necessário a data e hora</span>
                            <span v-if="errors.Moment">{{errors.Moment}}</span>
                        </div>
                    </div>
                    <!-- Property -->
                    <div v-if="!baseAlert" class="col-12 mt-5 mb-2" :class="{'col-lg-8': !selectedProperty, 'col-lg-4': selectedProperty}">
                        <label class="label-alert">Propriedade</label>
                            <SingleOptionDropdown :class="{'dropdown-toggle-invalid': submitted && !selectedProperty}" title="Escolha uma propriedade" v-model="selectedProperty" :options="propertyOptions" :index="1"></SingleOptionDropdown>                 
                            <div v-if="submitted && !selectedProperty" class="property-feedback">
                                <span>É necessário a Propriedade</span>
                            </div>
                    </div>
                    <!-- Module -->
                    <div v-show="baseAlert || selectedProperty" class="col-12 mt-5 mb-2" :class="{'col-lg-8': baseAlert, 'col-lg-4': selectedProperty}">
                        <template v-if="hasModule || alert.moduleId">
                            <label class="label-alert">Módulo</label>
                                <!-- has module -->
                                <SingleOptionDropdown v-if="modules.length || alert.moduleId" v-model="alert.moduleId" :options="modulesOptions" :index="2" :is-valid="!submitted || (submitted && !hasError('ModuleId'))" :class="{'disabled': baseAlert}"></SingleOptionDropdown>                 
                                <!-- without module -->
                                <div v-if="!modules.length && !alert.moduleId" >Ainda não tem módulos associados ao imóvel (apartamentos, lojas,...). Por favor aceda a "Editar detalhes" e faça a gestão dos módulos.</div>
                                <div v-if="submitted && hasError('ModuleId')" class="invalid-feedback">
                                    <span v-if="!$v.alert.moduleId.required">É necessário o Módulo</span>
                                    <span v-if="errors.ModuleId">{{errors.ModuleId}}</span>
                                </div>
                        </template>
                    </div>
                    <!-- Users -->
                    <div class="col-12 col-lg-4 mt-5 mb-2">
                        <label class="label-alert">Destinatário</label>
                        <OptionsDropdown :options="usersOptions" title="Escolha o(s) destinatário(s)" v-model="alert.users" :is-valid="!submitted || (submitted && !hasError('Users'))"></OptionsDropdown>
                        <div v-if="submitted && hasError('Users')" class="invalid-feedback">
                            <span v-if="!$v.alert.users.required">É necessário o Destinatário</span>
                            <span v-if="errors.Users">{{errors.Users}}</span>
                        </div>
                    </div>
                    <!-- Content -->
                    <div class="col-12 mt-5 mb-2">
                        <label class="label-alert">Conteúdo</label>
                        <textarea type="text" v-model="alert.content" class="form-control" :class="{ 'is-invalid': submitted && hasError('Content') }"/>
                        <div v-if="submitted && hasError('Content')" class="invalid-feedback">
                            <span v-if="!$v.alert.content.required">É necessário Conteúdo</span>
                            <span v-if="errors.Content">{{errors.Content}}</span>
                        </div>
                    </div>
                </div>
            <!-- Action button + loading -->  
            <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
            <CButton v-else type="button" class="btn align-self-end text-light mt-5" @click="submitClicked" id="save-button">Guardar</CButton>
        </CCardBody>
    </CCol>
</div>
</template>
 
<script>
import SingleOptionDropdown from '@/controls/SingleOptionDropdown.vue'
import OptionsDropdown from "@/controls/OptionsDropdown.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";
import Alert from "@/classes/Alert";
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import InputGetUsers from "@/classes/InputGetUsers";
import InputGetProperties from "@/classes/InputGetProperties";
import inputGetModule from "@/classes/inputGetModule";
import EventBus from '@/events/bus.js';

export default {
    components: { SingleOptionDropdown, OptionsDropdown },
    props:{    
        baseAlert:
        {
            type: Object,
            default: null,
        }
    },
    data()
    {
        return {
            submitted: false,
            alert: new Alert(),
            loading: false,
            selectedProperty: null,
            minDate : alert.moment,
            hasModule: false,
        }
    },
    validations: {
        alert: {
            title: { required },
            moduleId: { required },
            users: { required },
            moment: { required },
            content: { required },
        }
    },
    mounted: function()
    {
        this.getProperties(1, this.itemsPerPage);
        this.resetAlert();
        this.getUsersList(1, this.itemsPerPage);
        
        // Active have default value of true
        this.active = true;
    },
    computed:
    {
        ...mapState('alerts', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        ...mapState({
            listModules: state => state.modules.listOutput,
            listAccount: state => state.account.listOutput,
            listPropertie: state => state.properties.listOutput
        }),
        propertiesInfo: function() {
            return this.listPropertie && this.listPropertie.data && this.listPropertie.data.properties ? this.listPropertie.data.properties : null;
        },
        modules: function()
        {
            return this.listModules && this.listModules.data && this.listModules.data.modules ? this.listModules.data.modules : [];
        },
        usersInfo: function() {
            return this.listAccount && this.listAccount.data && this.listAccount.data.users ? this.listAccount.data.users : [];
        },
        propertyOptions: function() 
        {
            var result = [];
            if(this.propertiesInfo != null){ 
                this.propertiesInfo.forEach(function(property){
                    result.push({key: property.id.toString(), label: property.name});
                });
            }
            return result;
        },
        modulesOptions: function() 
        {
            var result = [];

            if(this.baseAlert)
            {
                result = [{key: this.baseAlert.module.id.toString(), label: this.baseAlert.module.name}];
            }
            else
            {
                this.modules.forEach(function(module){
                    result.push({key: module.id.toString(), label: module.name});
                });
            }
            
            return result;
        },
        usersOptions: function() 
        {
            var result = [];
            if(this.usersInfo != null){ 
                this.usersInfo.forEach(function(user){
                    if (user.name != JSON.parse(localStorage.getItem('user')).data.name)
                        result.push({key: user.id.toString(), label: user.name});                   
                });
            }
            return result;
        },
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
    },
    methods:{
        ...mapActions('account', ['listUsers']),
        ...mapActions({
            getModulesList: 'modules/getList',
            getPropertiesList: 'properties/getList'
        }),
        resetAlert(){
            if(!this.baseAlert)
            {
                this.alert = new Alert();
            }
            else
            {
                this.alert = UTILS_HELPER.deepClone(new Alert, this.baseAlert); 
                this.alert.moduleId = this.alert.module.id.toString();
                this.alert.users = this.alert.users.map(x=> x.id.toString());
            }
        },
        getProperties()
        {
            //Make request
            const currentPage = 1;
            const itemsPerPage = 999;
            const orderBy = 0;
            const propertyName = "id";           
        
            var input = new InputGetProperties(itemsPerPage, currentPage, orderBy, propertyName, this.searchText, true);

            this.getPropertiesList(input); 
        },
        getUsersList()
        {
            //Make request
            const currentPage = 1;
            const itemsPerPage = 999;
            const propertyName = "id";
            const orderBy = 0;

            var input = new InputGetUsers(currentPage, itemsPerPage, this.searchText, orderBy, propertyName, this.active);
            this.listUsers(input);       
        },
        getModules()
        {
            //Make request
            const currentPage = 1;
            const itemsPerPage = 0;
            const sorterValue = 0;
            const propertyName = "id";           
        
            var input = new inputGetModule(currentPage, itemsPerPage, sorterValue, propertyName, this.active);

            this.getModulesList(input); 
        },
        submitClicked(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid || !this.alert.isValid()) {
                return;
            }
            this.loading = true;
            this.$emit('submit-requested', this.alert);
        },
        hasError(fieldName)
        {
            return this.$v.alert[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        goToAlertsList(){
            this.$router.push({name: "AlertsList"});
        }
    },
    watch:
    {
        selectedProperty: function(val){
            // Get modules based on property id
            const propertyName = "id";

            const param = { propertyName, propertyId: val, active: true};
            this.getModulesList(param);
        },
        modules(val) {
            if(val) {
                this.hasModule = true;
            }
            if(val.length == 0)
            {
                this.alert.moduleId = null
            }
        },
        processTask: function(val)
        {
            if(val != null)
            {
                if(val)
                {
                    EventBus.$emit("success-alert-requested", { message: 'Executado com sucesso!', primaryButton: 'Confirmar', primaryCallback: this.goToAlertsList});
                }
                this.loading = false;
            }
        },
        baseAlert: function()
        {
            this.resetAlert();
        },
        alertOutput: function()
        {
            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        },
    }
  }
</script> 
 
<style scoped>
    .label-alert{
        color:rgb(78, 84, 200);
        font-weight: bold;
    }

    #save-button{
        background-color:rgb(78, 84, 200);
        border-radius: 10px;
    }

    #ownership-selector > span{
        color:gray;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector > span.active{
        color:rgb(143, 148, 251) ;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector.is-invalid > span
    {
        color: #dc3545;
    }
    >>>.dropdown-toggle[data-v-96d3e686]{
        background-color: white !important;
        min-height: 40px !important;
    }
    >>>.dropdown-toggle[data-v-159e9e70]{
        background-color: white !important;
        min-height: 40px !important;
    }

    .disabled
    {
        opacity: 0.6;
    }
    .property-feedback span{
        font-size: 0.875em;
        color: #dc3545;
    }
    .dropdown-toggle-invalid{
        border: 1px solid #dc3545;
        border-radius: 0.25rem;
    }
</style> 
export default class Alert
{
  constructor(title, content, moment, moduleId, users) 
  {
    this.title = title;
    this.content = content;
    this.moment = moment;
    this.moduleId = moduleId;
    this.users = users;
  }    
  
  isValid()
  {
    return this.title && this.content && this.moment && this.moduleId && this.users && this.users.length;
  }
}